import request from '../utils/request'

// 获取 自选类型 tabs
export function goodsgroupQuery(data) {
    return request({
        url: "/shop2/goods-group/query",
        method: "post",
        data
    })
}

// 获取 类型数据
export function groupGoods(data) {
    return request({
        url: "/shop2/goods/query",
        method: "post",
        data
    })
}

// j加入购物车
export function CartAdd(data) {
    return request({
        url: "/shop2/cart/add",
        method: "post",
        data
    })
}

// 获取已选项目 购物车数据
export function getCard(data) {
    return request({
        url: "/shop2/cart/list2",
        method: "post",
        data
    })
}


// 项目合成 商品
export function createGoods(data) {
    return request({
        url: "/shop2/goods/create",
        method: "post",
        data
    })
}