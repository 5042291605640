<template>
    <div class="selectProject">
        <div class="select-tabs" :class="{'tabs-popup':detailedShow}">
            <van-tree-select :items="items" class="myTreeTabs" :main-active-index.sync="active" @click-nav="navSwitch">
                <template #content>
                    <div class="content-main">
                        <div class="main-list" @scroll="onScroll" @touchstart="touchstart" @touchend="touchend" id="goodsScroll">
                            <div class="mian_item" :ref="item.tagId +'_scroll'" :data-index="index"  :key="index" v-for="(item,index) in goodsList" >
                                <p class="alltile"  :key="index">{{item.tagName}}（{{item.goodsList.length}}{{$t('optional.checkItem')}}）</p>
                                <div class="list-item" v-for="(citem,cindex) in item.goodsList" :key="index+'oo'+cindex" :class="{'noborder':cindex==(item.goodsList.length - 1)}">
                                    <div class="project-details">
                                        <div class="details-txt">
                                            <p class="text">
                                                <span>{{citem.name}}</span>
                                                <van-popover
                                                    v-model="citem.showPopover"
                                                    trigger="click"
                                                    class="mysubtitle"
                                                    placement="top"
                                                    >
                                                    {{citem.subtitle||$t("optional.empty")}}
                                                    <template #reference>
                                                        <i class="icon"></i>
                                                    </template>
                                                </van-popover>
                                            </p>
                                        </div>
                                        <p class="price">
                                            ¥{{citem.sellPrice}}
                                        </p>
                                    </div>
                                    <div class="project-describe">
                                        <p class="describe-txt">
                                            {{citem.slogan}}
                                        </p>
                                        <span class="select-box" @click="addCart(citem)">
                                            <i class="select-icon" :class="{'activeIcon':citem.selectType}"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </van-tree-select>
        </div>
        <div class="select-submit" :class="{'submitfiexd':detailedShow}">
            <div class="project-price">
                <p class="allprice">{{$t('optional.summation')}}：<span>¥</span><span class="price-num">{{allPrice}}</span></p>
                <!-- arrow-up -->
                <p class="project-num" v-if="activeList.length>0">{{$t('optional.general')}}{{activeList.length}}{{$t('optional.checkItemes')}} <span @click="detailedShow = true">{{$t('optional.particulars')}}<van-icon name="arrow-down" /></span></p>
            </div>
            <div class="project-btn" @click="createdGoods">
                {{$t('optional.selected')}}
            </div>
        </div>
        <van-popup v-model="detailedShow" position="bottom" class="mydetailed" >
            <div class="detailed-box">
                <p class="title">{{$t('optional.screenParticulars')}} <van-icon @click="detailedShow = false" name="cross" /></p>
                <div class="detailed-list">
                    <div class="detailedItem" v-for="(item,index) in activeList" :key="index">
                        <p class="name">{{item.name}}</p>
                        <p class="price">¥{{item.sellPrice}}</p>
                        <i class="icon-close" @click="projectClose(item)"></i>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { goodsgroupQuery, groupGoods, CartAdd, getCard, createGoods } from "@/api/Optional";
export default {
    data(){
        return{
            active: 0,
            items: [],
            detailedShow:false,
            goodsList:[],
            showPopover:false,
            activeList:[],
            scrollType:true,
            oldScrollTop: 0,
            CartList:[],
            gDType:true,
        }
    },
    async created(){
        document.title = this.$t("optional.optionalMall");
        // await this.getGoodsGroupdata();
        await this.getGroupGoods();
        this.getCartList();
    },
    computed:{
        allPrice(){
            this.activeList = [];
            let price = 0;
            for(let it in this.goodsList){
                for(let g in this.goodsList[it].goodsList){
                    if(this.goodsList[it].goodsList[g].selectType){
                        price += Number(this.goodsList[it].goodsList[g].sellPrice);
                        this.activeList.push({
                            findex:it,
                            cindex:g,
                            sellPrice:this.goodsList[it].goodsList[g].sellPrice,
                            name:this.goodsList[it].goodsList[g].name,
                            id:this.goodsList[it].goodsList[g].id,
                        })
                    }
                }
            }
            return price.toFixed(2);
        }
    },
    methods:{
        async getGoodsGroupdata(){
            let datadd = {
                gender:this.$route.query.sexType
            };
            const toast = this.$Toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				message: this.$t("message.loadingTxt")
			});	
            let result = await goodsgroupQuery(datadd);
            // toast.clear();
            if(result.data.errorCode == 0){
                result.data.data.map(item=>{
                    this.items.push({
                        text:item.groupName,
                        id:item.groupId
                    })
                })
                this.items.unshift({
                    text:this.$t("optional.all"),
                    id:"null"
                })
            }else{

            }
        },
        async getGroupGoods(){
            let datadd = {
                gender:this.$route.query.sexType,
            }
            const toast = this.$Toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				message: this.$t("message.loadingTxt")
			});	
            let result = await groupGoods(datadd);
            toast.clear();
            if(result.data.errorCode == 0){
                console.log(result.data.data);
                result.data.data.map(item=>{
                    this.items.push({
                        text:item.tagName,
                        id:item.tagId
                    })
                    item.goodsList.map(citem=>{
                        citem.selectType = false;
                        citem.showPopover = false;
                    })
                })
                this.goodsList = result.data.data;

                console.log(this.goodsList);
                // this.items.unshift({
                //     text:"全部",
                //     id:"null"
                // })
            }else{

            }
        },
        async addCart(citem){
            const toast = this.$Toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				message: this.$t("message.loadingTxt")
			});
            let datadd = {
                goodsId:citem.id,
                goodsQty:citem.selectType?-1:1
            }
            let result = await CartAdd(datadd);
            toast.clear(result);
            if(result.data.errorCode == 0){
                citem.selectType = !citem.selectType;
            }else{
                this.$toast(result.data.errorMsg);
            }
        },
       
        // 获取
        async getCartList(){
            // CartList
            let result = await getCard();
            if(result.data.errorCode == 0){
                // console.log(result);
                this.activeList = [];
                this.CartList = result.data.data;
                // console.log("lll");
                for(let it in this.CartList){
                    for(let g in this.goodsList){
                        for(let j in this.goodsList[g].goodsList){
                            console.log(this.CartList[it].id ,this.goodsList[g].goodsList[j]);
                            if(this.CartList[it].id == this.goodsList[g].goodsList[j].id){
                                this.goodsList[g].goodsList[j].selectType = true;
                                this.activeList.push({
                                    findex:g,
                                    cindex:j,
                                    sellPrice:this.goodsList[g].goodsList[j].sellPrice,
                                    name:this.goodsList[g].goodsList[j].name,
                                    id:this.goodsList[g].goodsList[j].id,
                                })
                            }
                        }
                    }
                }
            }else{

            }
        },
        // 导航描点跳转
        navSwitch(){
            if(this.gDType){
                this.scrollType = false;
                console.log(this.active);
                let idStr = this.goodsList[this.active].tagId+'_scroll';
                let scrollHeight = this.$refs[idStr][0].offsetTop - 30;
                document.getElementById('goodsScroll').scrollTop = scrollHeight;
                setTimeout(() => {
                    this.scrollType = true;
                }, 1000);
            }
        },
         touchstart(e){
            this.gDType = false;
        },
        touchend(e){
            setTimeout(()=>{
                this.gDType = true;
            },500)
        },
        // 弹窗删除商品
        async projectClose(item){
            await this.addCart(this.goodsList[item.findex].goodsList[item.cindex]);
            this.goodsList[item.findex].goodsList[item.cindex].selectType = false;
            if(this.activeList.length == 0){
                this.detailedShow = false;
            }
        },
        // 创建商品
        async createdGoods(){
            if(this.activeList.length == 0){
                this.$toast(this.$t("optional.pleaseMedical"));
                return
            }
            let datadd={};
            datadd.goodsIds = [];
            for(let it in this.activeList){
                datadd.goodsIds.push(this.activeList[it].id);
            }
            const toast = this.$Toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				message: this.$t("message.loadingTxt")
			});
            let result = await createGoods(datadd);
            toast.clear();
            if(result.data.errorCode == 0){
                let urlData = {};
                urlData.goodsQty = 1;
                if(this.$route.query._dk_){
                    urlData._dk_ = this.$route.query._dk_ ;
                }
                urlData.goodsId = result.data.data;
                this.$router.push({
                    path:"/shop/create-order",
                    query:{
                        goods:JSON.stringify([urlData]),
                        _dk_:urlData._dk_
                    }
                })
            }else{
                this.$toast(this.$t("optional.createdFail"));
            }
        },
        // 滚动回调
        onScroll(e){
            if(this.scrollType){
                // this.
                let index = 0;
                // console.log(e);
                let scrollStep = e.target.scrollTop - this.oldScrollTop;

                this.oldScrollTop = e.target.scrollTop;
                let titleAll = document.getElementsByClassName('mian_item');
                for(let i in titleAll){
                    if(scrollStep<0){
                        let judge = e.target.scrollTop >= titleAll[i].offsetTop-30;
                        if (judge) {
                            index =  titleAll[i].dataset.index;
                        }
                    }else{
                        let rect = titleAll[i].getBoundingClientRect();
                        if((rect.top+rect.height >= (window.innerHeight || document.documentElement.clientHeight) || rect.bottom <= 0)&&titleAll[i].offsetTop>e.target.scrollTop){
                            index = titleAll[i].dataset.index ;
                            break;
                        }
                    }
                }
                setTimeout(()=>{
                    this.active = index;
                },500)
                // }else{
                //     this.active = index;
                // }
            }
        }
    }
}
</script>

<style lang="scss">
.van-popup .van-popover__content{
    font-size: 0.5rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #909399;
    padding: 0.3rem;
}
.selectProject{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #F5F6FA;
    overflow: hidden;
    
    .select-tabs{
        flex: 1;
        margin-top: 0.2rem;
        background: #FFFFFF;
        overflow: hidden;
        .myTreeTabs{
            height: 100% !important;
            .van-tree-select__nav{
                // width: 4.2rem;
                flex: 211;
                .van-sidebar-item {
                    height: 2rem;
                    box-sizing: border-box;
                    font-size: 0.52rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #303133;
                    display: flex;
                    align-items: center;
                    padding-left: 0.6rem;
                    .van-sidebar-item__text{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        height: max-content; 
                        height: 2rem;
                        line-height: 2rem;
                    }
                }
                .van-sidebar-item--select{
                    &::before{
                        width: 0.16rem;
                        height: 0.72rem;
                        background: #FF5252;
                        border-radius: 0px 0.12rem 0.12rem 0px;
                    }
                }
            }
            .van-tree-select__content{
                flex: 539;
                .content-main{
                    display: flex;
                    flex-direction: column;
                    padding:0 .6rem;
                    height: 100%;
                    box-sizing: border-box;
                    overflow: hidden;
                    .main-list{
                        flex: 1;
                        padding-top: 0.4rem;
                        .alltile{
                            height: 0.92rem;
                            line-height: 0.92rem;
                            margin-bottom: 0.4rem;
                            background: #F5F6FA;
                            padding: 0 0.32rem;
                            box-sizing: border-box;
                            font-size: 0.48rem;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #606266;
                            flex: auto 0 0;
                        }
                        overflow: auto;
                        .list-item{
                            padding: 0.6rem 0;
                            border-bottom: 0.02rem solid #ECEEF5;
                            .project-details{
                                display: flex;
                                .details-txt{
                                    flex: 1;
                                    .text{
                                        padding-right: 0.8rem;
                                        position: relative;
                                        font-size: 0.6rem;
                                        font-family: PingFangSC-Medium, PingFang SC;
                                        font-weight: 600;
                                        color: #303133;
                                        display: inline-block;
                                        line-height: 0.8rem;
                                        .van-popover__wrapper{
                                            position: absolute;
                                            top: 0.08rem;
                                            right: 0.08rem;
                                            .icon{
                                                width: 0.64rem;
                                                height: 0.64rem;
                                                display: block;
                                                background: url("~@/assets/img/warning-icon.png") no-repeat;
                                                background-size: 100% 100%;
                                            }
                                        }
                                    }
                                }
                                .price{
                                    flex: auto 0 0;
                                    font-size: 0.52rem;
                                    font-family: PingFangSC-Medium, PingFang SC;
                                    font-weight: 600;
                                    margin-left: 0.3rem;
                                    color: #303133;
                                }
                            }
                            .project-describe{
                                display: flex;
                                margin-top: 0.2rem;
                                align-content: flex-end;
                                .describe-txt{
                                    flex: 1;
                                    font-size: 0.52rem;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #909399;
                                }
                                .select-box{
                                    padding: 0.2rem 0.2rem 0.2rem 0.4rem;
                                    flex: auto 0 0;
                                    display: flex;
                                    // margin-left: 0.2rem;
                                    align-items: flex-end;
                                    .select-icon{
                                        height: 0.72rem;
                                        width: 0.72rem;
                                        display: block;
                                        background:url("~@/assets/img/oriject-select.png") no-repeat;
                                        background-size: 100% 100%;
                                    }   
                                    .activeIcon{
                                        background:url("~@/assets/img/project-active.png") no-repeat;
                                        background-size: 100% 100%;
                                    }
                                }
                            }
                        }
                        .noborder{
                            border-bottom: 0;
                        }
                    }
                }
            }
        }
    }
    .select-submit{
        box-sizing: border-box;
        height: 2.8rem;
        padding: 0.6rem;
        display: flex;
        align-items: center;
        flex: auto 0 0;
        background: #FFFFFF;
        border-top: 1px solid #E4E7ED;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .project-price{
            font-size: 0.56rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #303133;
            line-height: 0.8rem;
            span{
                color: #FF5252;
            }
            .price-num{
                font-size: 0.84rem;
                font-family: DINAlternate-Bold, DINAlternate;
                font-weight: bold;
                color: #FF5252;
                line-height: 0.96rem;
                letter-spacing: 1px;
            }
            .project-num{
                font-size: 0.4rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #606266;
                span{
                    margin-left: 0.32rem;
                }
            }
        }
        .project-btn{
            width: 3.24rem;
            height: 1.28rem;
            background: #FF5252;
            border-radius: 0.8rem;
            line-height: 1.28rem;
            text-align: center;
            font-size: 0.52rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
        }
    }
    .tabs-popup{
        padding-bottom: 2.8rem;    
    }
    .submitfiexd{
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 99999999999999;
    }
    .mydetailed{
        border-radius: 0.64rem 0.64rem 0px 0px;
        .detailed-box{
            padding-bottom: 2.8rem;
            height: 14rem;
            display: flex;
            flex-direction: column;
            .title{
                padding: 0.6rem 0;
                font-size: 0.64rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #303133;
                line-height: 0.9rem;
                position: relative;
                text-align: center;
                .van-icon {
                    position: absolute;
                    right: 0.6rem;
                    top: 50%;
                    font-size: 0.6rem;
                    padding: 0.1rem;
                    transform: translate(0,-50%);
                }
            }
            .detailed-list{
                flex: 1;
                overflow: auto;
                .detailedItem{
                    display: flex;
                    align-items: flex-start;
                    // height: 1.44rem;
                    padding:0.3rem 0.6rem;
                    .name{
                        flex: 1;
                        font-size: 0.56rem;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #303133;
                        line-height: 0.7rem;
                    }
                    .price{
                        flex: auto 0 0;
                        line-height: 0.7rem;
                        font-size: 0.52rem;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 600;
                        color: #303133;
                        width: 2.5rem;
                        text-align: right;
                    }
                    .icon-close{
                        height: 0.72rem;
                        width: 0.72rem;
                        padding: 0.1rem;
                        margin-left: 0.5rem;
                        background: url("~@/assets/img/project-select-close.png") no-repeat center;
                        background-size: 0.72rem 0.72rem;
                    }
                }
            }
        }
    }
}
</style>